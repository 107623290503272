import React, { useState } from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import List from "./components/List/List";
import Apt from "./components/Apt/Apt";

function App() {

  return (
        // <Home />
    <BrowserRouter>
      <Routes>
        <Route path ="/" element={<Home />} />
        <Route path = "/List" element = {<List />} />
        <Route path = "/List/:id" element={<Apt />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
