import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "../style.css";


function Apt(){

  
        const [slideNumber, setSlideNumber] = useState(0);
        const [open, setOpen] = useState(0);
    const photos = [
        {
          src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707778.jpg?k=56ba0babbcbbfeb3d3e911728831dcbc390ed2cb16c51d88159f82bf751d04c6&o=&hp=1",
        },
        {
          src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707367.jpg?k=cbacfdeb8404af56a1a94812575d96f6b80f6740fd491d02c6fc3912a16d8757&o=&hp=1",
        },
        {
          src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261708745.jpg?k=1aae4678d645c63e0d90cdae8127b15f1e3232d4739bdf387a6578dc3b14bdfd&o=&hp=1",
        },
        {
          src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707776.jpg?k=054bb3e27c9e58d3bb1110349eb5e6e24dacd53fbb0316b9e2519b2bf3c520ae&o=&hp=1",
        },
        {
          src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261708693.jpg?k=ea210b4fa329fe302eab55dd9818c0571afba2abd2225ca3a36457f9afa74e94&o=&hp=1",
        },
        {
          src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707389.jpg?k=52156673f9eb6d5d99d3eed9386491a0465ce6f3b995f005ac71abc192dd5827&o=&hp=1",
        },
      ];
const handleOpen = (index) => {
    setSlideNumber(index); 
    setOpen(true);

}

const handleMove = (direction) => {
    let newSlideNumber;
    if(direction==="left"){
        newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1
    }else {
        newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1
    }
    setSlideNumber(newSlideNumber );
};
    return (
        <>
          <Navbar />
          <div className="apt-container container">
          { open && <div className="slider">
          <i className="fa-solid fa-xmark" onClick={()=>setOpen(false)}></i>
          <i className="fa-solid fa-circle-arrow-left arrow" onClick={() => handleMove("left")} ></i>
          <div className="sliderWrapper">
            <img src={photos[slideNumber].src}  className="sliderImg"/>
          </div>
          <i className="fa-solid fa-circle-arrow-right arrow" onClick={() => handleMove("right")}></i>
            </div>
          }

            <div className="aptWrapper mt-4">
                <h1 className="aptTitle">Azure Residence</h1>
                <div className="aptAddress">
                    <span>Room No. 212, Uptown Mall. Pasig City</span>
                </div>
                <div className="aptImages">
                    {
                    photos.map((photo,index) => (
                        <div className="aptImageWrapper">
                            <img onClick={() => handleOpen(index)} src={photo.src} className="aptImg" />

                        </div>
                    ))
                    }
                </div>
                <div className="aptDetails">
                <div className="row">
                    <div className="col-md-8 col-sm-8">
                        <div className="inner-details col-md-8">
                            <h1 className="fs-5 mt-3 ms-4">About this rental</h1>
                            <p class="ms-4">Condo Unit | 2 Bedrooms | 1 Bathroom</p>
                        </div>
              
 
      <div className="sub-details mt-2 ms-3">
        <p><i className="fa-solid fa-location-dot me-1"></i> Great Location</p>
        <span clas="ms-3 ">90% of recent guests gave the location a 5-star rating.</span>
        <p>
          <i className="fa-solid fa-key me-1"></i> Great check-in experience
        </p>
        <span clas="ms-3">90% of recent guests gave the location a 5-star rating.</span>
        <p>
          <i className="fa-sharp fa-regular fa-calendar me-2"></i>Free
          Cancellation before 5 days to check date
        </p>
      </div>
      <div className="sub-info2 mt-4">
        <h1 className="fs-5 mt-3 ms-3">What this place offers</h1>
        <div className="row">
          <div className="col-md-6">
            <div className="ms-3 mt-3">
              <p><i className="fa-solid fa-wifi me-2"></i>Wifi</p>
            </div>
            <div className="ms-3 mt-3">
              <p>
                <i className="fa-solid fa-wifi me-2"></i>Easy Transportation
              </p>
            </div>
            <div className="ms-3 mt-3">
              <p><i className="fa-solid fa-wifi me-2"></i>Safe Environment</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ms-3 mt-3">
              <p><i className="fa-solid fa-wifi me-2"></i>Wifi</p>
            </div>
            <div className="ms-3 mt-3">
              <p>
                <i className="fa-solid fa-wifi me-2"></i>Easy Transportation
              </p>
            </div>
            <div className="ms-3 mt-3">
              <p><i className="fa-solid fa-wifi me-2"></i>Safe Environment</p>
            </div>
          </div>
        </div>
      </div>
                    </div>
                <div className="col-md-4">
              <h2>
                <b>P945</b>
              </h2>
              <button className="btn btn-primary">Book Now</button>
                </div>

                </div>
                </div>
            </div>
          </div>
          <Footer/>
        </>
      
    );
};
export default Apt;