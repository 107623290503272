import React from "react";
import img01 from "../assets/img/img02.webp";
function Featured(){
    return(
        <>
          <section className="container  mt-5 gap-0">
          <div className="section-list">
                    {/* <a target="_blank" href="#" className="booked-target"></a> */}
                    <div className="featured">
                        <div className="featuredItem">
                            <img src='https://i.pinimg.com/originals/92/6c/71/926c716e96f9841c2c35877dc2dc7b05.jpg' className="featuredImg  w-100 h-100"/>
                            <div className="featuredTitle">
                                <h1>BGC</h1>
                                <h2>5 Unit</h2>
                            </div>
                        </div>
                    {/* <a target="_blank" href="#" className="booked-target"></a> */}
                        <div className="featuredItem">
                            <img src='https://th.bing.com/th/id/R.06abecc63d3faa9bf5acf37f169ebc5f?rik=syj%2bDMuZtHm1NQ&riu=http%3a%2f%2f3.bp.blogspot.com%2f-siU8Dq5ovEY%2fVO09v_88-2I%2fAAAAAAAAAYU%2f4oICTRdpSSs%2fs1600%2fbrio.jpg&ehk=xcqMoq3j1RUGXmQjthCf2yoDnRFFKhfl69r6Vy7TKq0%3d&risl=&pid=ImgRaw&r=0' className="featuredImg  w-100 h-100" />
                            <div className="featuredTitle">
                                <h1>Metro Manila</h1>
                                <h2>4 Unit</h2>
                            </div>
                        </div>
                    {/* <a target="_blank" href="#" className="booked-target"></a> */}
                        <div className="featuredItem">
                            <img src='https://i2.wp.com/www.preselling.com.ph/wp-content/uploads/2016/08/San-Lorenzo-Place.jpg?fit=1920%2C1080&ssl=1' className="featuredImg w-100 h-100"  />
                            <div className="featuredTitle">
                                <h1>Makati</h1>
                                <h2>2 Unit</h2>
                            </div>
                        </div>
       
                    </div>
                </div>         
         </section>
        </>
    );
};

export default Featured;
