import "../style.css";
import React from "react";
import img02 from "../assets/img/img02.webp";
function SearchItem(){
    return(
        <>
            <div className="searchItem">
            <img src={img02} className="searchItemImg img-fluid" alt={img02}/>
      
            <div className="searchDesc">
                <h1 className="searchTitle">Azure Residence</h1>
                <span className="searchSubtitle">Studio Apartment, Air conditioned</span>
                <span className="searchFeatures">Entire Studio | 1 bedroom | 1 bathroom | 40sqm.</span>
                <span className="searchItemPrice fs-3 fw-bold">P3,500</span>
                <button className="btn btn-primary w-50 p-3">Check Availability</button>
            </div>
    
            </div>
        </>
    );

};

export default SearchItem;
