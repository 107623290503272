import React, {useState} from "react";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {format} from "date-fns"
import { useNavigate } from "react-router-dom";
function Hero() {
  const [destination, setDestination] = useState("");
    const [openDate, setOpenDate] = useState(false);
    const [date, setDate] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
      
      const [openOptions, setOpenOptions] = useState(false);
      const [options, setOptions] = useState({   
        adult: 0,
        children: 0

        });

        const navigate  =  useNavigate();
        const handleOption = (name, operation) => {
            setOptions((prev) => {
                return{
                    ...prev, 
                    [name]: operation === "max" ? options[name] + 1 :options[name] - 1,
                };
            })
        }

        const handleSearch = () => {
          navigate("/List", {state:{destination,date,options }})
        }
    return (
        <>
         <section className="hero-con  mb-4 bg-light rounded-3 ">
            <div className="container-fluid hero-bg text-center pt-5 ">
            <h1 className="display-5  mb-3 disp">
                Room to stay. Book now
            </h1>
            <div className="d-flex flex-lg-row flex-wrap justify-content-center ">
                <form className="d-flex flex-sm-column flex-lg-row flex-md-col flex-column " 
                />
                 <div className="d-flex ">  
              <div className="col-md-3 headerSearch">
                <input
                  type="text"
                  name="cityRegion"
                  id="cityRegion"
                  className="headerSearchInput form-control rounded-0 mb-1 py-3 text-center text-muted"
                  placeholder="Search Unit/Apt"
                  aria-describedby="helpId"
                  onChange={e=>setDestination(e.target.value)}
                />
              </div>
              <div className="col-md-5 headerSearch">
              <div className="">
                <span onClick={() => setOpenDate(!openDate)} className="headerSearchText form-control p-3 rounded-0 text-muted">{`${format(date[0].startDate, "MM/dd/yyyy")} - ${format(date[0].endDate, "MM/dd/yyyy")} `}</span>
            {openDate && <DateRange
                editableDateInputs={true}
                onChange={item => setDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={date}
                className="date"
                minDate={new Date()}
            />  }  
              </div>
            </div>
            <div className="col-md-4 headerSearch">
              <div className="">
            <span  onClick={() => setOpenOptions(!openOptions)} class="headerSearchText form-control p-3 rounded-0 text-muted">{`${options.adult} adults ${options.children} children`}</span>
                {openOptions && <div className="options">
                    <div className="optionItem">
                    <span className="optionText">Adult</span>
                    <div className="optionCounter">
                        <button className="optionCounterBtn btn btn-outline" onClick={() => handleOption("adult", "min") } disabled={options.adult <=0}>-</button>
                        <span className="optionCounterNumber">{options.adult}</span>
                        <button className="optionCounterBtn btn btn-outline" onClick={() => handleOption("adult", "max") }>+</button>
                    </div>

                    </div>
                    <div className="optionItem">
                    <span className="optionText">Children</span>
                    <div className="optionCounter">
                        <button className="optionCounterBtn btn btn-outline" onClick={() => handleOption("children", "min")} disabled={options.children <=0 }>-</button>
                        <span className="optionCounterNumber">{options.children}</span>
                        <button className="optionCoutnterBtn btn btn-outline" onClick={() => handleOption("children", "max") }>+</button>
                    </div>
                    </div>
                </div>}
              </div>  
            </div>
            <div className="col-2 headerSearch">
              <button className="btn btn-primary form-control rounded-0 mb-1 py-3" id="btnSearch" onClick={handleSearch}>Search</button>
            </div>
            </div>
            </div>
            </div>
         </section>
        </>
    );
};

export default Hero;