import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Router, RouterProvider } from 'react-router-dom';
import User from "./components/Admin/User";
import ErrorView from './components/Home/ErrorView';
import List from './components/List/List';
import Apt from  './components/Apt/Apt';
import Room from './components/Admin/Room';
import Customer from './components/Admin/Customer';


// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <App />,
//     errorElement: <ErrorView />,
//     children: [
//       {
//         path: '/List',
//         element: <List />,
//       },
//       {
//         path: '/List/:id',
//         element:<Apt />,
//       },
//       {
//         path: '/admin/user',
//         element: <User />,
//       },
//       {
//         path: '/admin/rooms',
//         element: <Room />,
//       },
//       {
//         path: '/admin/customer',
//         element: <Customer />,
//       },
//     ],
//   },
// ]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <RouterProvider router={router} /> */}
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
