import "../style.css";
import Navbar from "../Navbar/Navbar";
import {useLocation} from 'react-router-dom';
import React, { useState } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../SearchItem/SearchItem";
import Footer from "../Footer/Footer";
function List(){

    const location = useLocation();
    const [destination, setDestination] = useState(location.state.destination);
    const [date, setDate] = useState(location.state.date);
    const [openDate, setOpenDate] = useState(false);
    const [options, setOptions] = useState(location.state.options);
    return(
        <>
        <Navbar />
        <div className="listContainer container">
            <div className="listWrapper">
                <div className="listSearch p-4"> 
                <h1 className="listTitle"></h1>  
                <div className="listItem">
                    <label className="text-white">Destination</label>
                    <input type="text" placeholder={destination} />
                </div>
                <div className="listItem">
                    <label className="text-white">Check-in Date</label>
                <span onClick={() => setOpenDate(!openDate)} className="headerSearchText p-3 rounded-0 text-muted">{`${format(date[0].startDate, "MM/dd/yyyy")} - ${format(date[0].endDate, "MM/dd/yyyy")} `}</span>
                {openDate && (<DateRange 
                    onChange={item =>setDate([item.selection]) }
                    minDate={new Date()}
                    ranges={date}
                />)}
                </div>
                <div className="listItem">
                <label className="text-white">Options</label>
                    <div className="listOptionItem">
                        <span className="listOptionText text-white">
                            Min Price <small>per night</small>
                        </span>
                        <input type="number"  className="listOptionInput"/>
                    </div>
                    <div className="listOptionItem">
                        <span className="listOptionText text-white">
                            Max Price <small>per night</small>
                        </span>
                        <input type="number"  className="listOptionInput"/>
                    </div>
                    <div className="listOptionItem">
                        <span className="listOptionText text-white">
                           Adult:
                        </span>
                        <input type="number" min={1}  className="listOptionInput" placeholder={options.adult}/>
                    </div>
                    <div className="listOptionItem">
                        <span className="listOptionText text-white">
                           Children:
                        </span>
                        <input type="number" min={0}  className="listOptionInput" placeholder={options.children}/>
                    </div>
                </div>
                <button className="btn btn-primary w-100 text-white">Search</button>
                </div>
                <div className="listResult">
                    <SearchItem />
                    <SearchItem />
                    <SearchItem />
                    <SearchItem />

                </div>
            </div>
        </div>
        <Footer />
        </>
      
    );

};

export default List;