import React from "react";
import Hero from "../Hero/Hero";
import Navbar from "../Navbar/Navbar";
import Featured from "../Featured/Featured";
import LocationList from "../LocationList/LocationList";
import Footer from "../Footer/Footer";
import Dashboard from "../Admin/Dashboard";



function Home(){
    return (
        <>
            <Navbar />
            <Hero />
            <Featured />
            <LocationList />
            <Footer />
            {/* <Dashboard/> */}
        </>
      
    );

};
export default Home;