import React from "react";

function Footer(){
    return(
    <>
    <footer className="container-fluid bg-light g-0">
            <div className="container">
                <div className="row mt-5 py-3 justify-content-around">
                <div className="col-md-6">
                    &copy;2023 Unwind Inc. <a href="#">Terms</a> | <a href="#">Site map </a>| <a href="#">Terms</a> | <a href="#">Privacy</a>
                </div>
                
                <div className="col-md-6 text-end">
                    <i className="fa-brands fa-facebook-f fs-5 me-3"></i>
                    <i className="fa-brands fa-twitter fs-5 me-3"></i>
                    <i className="fa-brands fa-instagram fs-5 me-3"></i>
                </div>
            </div>
      </div>
    </footer>
    </>
    );
};

export default Footer;