import "../core.min.css";
import "../style.css";
import React from "react";

function Navbar(){
    return (
        <>
            <header className="container-fluid">
    <nav className="navbar navbar-expand-lg  mt-2">
      <div className="container-fluid">
        <a className="navbar-brand fs-3 ms-5" href="#">Unwind</a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarcollapse"
          aria-controls="navbarcollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarcollapse"
        >
    
          <div className="nav-item">
            {/* <button className="navButton btn">Register</button>
            <button className="navButton btn">Login</button> */}
          </div>
        </div>
      </div>
    </nav>
  </header>

        </>
    );
};

export default Navbar;